<template>
  <b-card>
      <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2">

        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Title')" label-for="title">
                  <validation-provider #default="{ errors }" rules="required" name="Title">
                    <b-form-input id="title" v-model="title" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Date')" label-for="dtDate">
                  <validation-provider #default="{ errors }" rules="required" name="Title">
                    <b-form-input type="date" id="dtDate" v-model="dtDate" :state="errors.length > 0 ? false:null" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" lg="6">
                <b-form-group
                  label=""
                  label-for="contact-options"
                  label-class="mb-1">
                  <div class="demo-inline-spacing">
                    <b-form-checkbox
                      v-model="isActive"
                      class="custom-control-primary">
                      {{ $t('Active')}}
                    </b-form-checkbox>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-form-group :label="$t('Content')" label-for="content">
                  <validation-provider #default="{ errors }" rules="required" name="Content">
                    <b-form-textarea
                      id="content"
                      v-model="content"
                      class="source-input"
                      placeholder="Content"
                      rows="8"
                      no-auto-shrink
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <save-button 
              :onClickSaveAndClose="saveItem.bind(this, true)"
              :onClickSave="saveItem.bind(this, false)"
              />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, min, max,
} from '@validations'
import { createHelpers } from 'vuex-map-fields';
import Ripple from 'vue-ripple-directive'
import SaveButton from '@/components/SaveButton.vue';

import ApiService from '@/common/api.service'
// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// Alert
import AlertService from '@/common/alert.service'

const { mapFields } = createHelpers({
  getterType: 'dataUpdateManagement/getDataUpdateField',
  mutationType: 'dataUpdateManagement/updateDataUpdateField',
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      min,
      max,
      pictureForm: new FormData(),
    }
  },
  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('dataUpdateManagement/fetchDataUpdate', { id: paramId })
          .then(response => {
            if (response.statusCode === 200) {
              this.isLoad = true;
              this.$store.commit('dataUpdateManagement/setDataUpdateField', response.result);
            } else {
              AlertService.error(this, response.message)
            }
          })
      } else {
        this.isLoad = true;
      }
    },
    saveItem(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const query = this.$store.getters['dataUpdateManagement/getDataUpdateForm'];
          this.$store.dispatch('dataUpdateManagement/saveDataUpdate', query)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                })

                if (isClose) {
                  this.$router.go(-1)
                } else {
                  this.$store.commit('dataUpdateManagement/setDataUpdateField', response.result);
                  this.$router.push({ name: 'manage-data-update-save', params: { id: response.result.id } });
                }
              } else {
                AlertService.error(this, response.message)
              }
            });
        }
      })
    },
  },
  computed: {
    ...mapFields([
      'id',
      'isActive',
      'title',
      'content',
      'dtDate',
    ]),
  },
  created() {
    this.$store.commit('dataUpdateManagement/resetDataUpdateField');
    this.fetchItem();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
</style>
